<template>
  <div class="case" id="lshCase">
    <div class="lsh-case">
      <p class="lsh-title">行业案例</p>
      <div class="business-list">

        <div class="list-item" id="3C">
          <div class="left">
            <div class="icon">
              <img src="../assets/img/icon1.png" alt="">
            </div>
            <div class="l-title" >
              3C行业
            </div>
          </div>
          <div class="center">
            <div class="box" v-for="(item, key) in list3cArray" :key="key">
              <div class="icon">
                <img v-lazy="item.bgi" alt="" style="width:210px;height:160px;">
              </div>
              <div class="c-title">{{item.title}}</div>
            </div>
          </div>
          <div class="right">
            <a href="javascript:void(0);" class="more btn">
              <span style="font-size: 16px;">MORE</span>
              <span style="padding-left: 30px; font-size: 20px;">+</span>
            </a>
          </div>
        </div>

        <div class="list-item" id="car">
          <div class="left">
            <div class="icon">
              <img src="../assets/img/icon3.png" alt="">
            </div>
            <div class="l-title">
              汽车行业
            </div>
          </div>
          <div class="center">
            <div class="box" v-for="(item, key) in listCarArray" :key="key">
              <div class="icon">
                <img v-lazy="item.bgi" alt="" style="width:210px;height:160px;">
              </div>
              <div class="c-title">{{item.title}}</div>
            </div>
          </div>
          <div class="right">
            <a href="javascript:void(0);" class="more btn">
              <span style="font-size: 16px;">MORE</span>
              <span style="padding-left: 30px; font-size: 20px;">+</span>
            </a>
          </div>
        </div>

        <div class="list-item" id="battery">
          <div class="left">
            <div class="icon">
              <img src="../assets/img/icon4.png" alt="">
            </div>
            <div class="l-title">
              锂电行业
            </div>
          </div>
          <div class="center">
            <div class="box" v-for="(item, key) in listBatteryArray" :key="key">
              <div class="icon">
                <img v-lazy="item.bgi" alt="" style="width:210px;height:160px;">
              </div>
              <div class="c-title">{{item.title}}</div>
            </div>
          </div>
          <div class="right">
            <a href="javascript:void(0);" class="more btn">
              <span style="font-size: 16px;">MORE</span>
              <span style="padding-left: 30px; font-size: 20px;">+</span>
            </a>
          </div>
        </div>

        <div class="list-item" id="medical">
          <div class="left">
            <div class="icon">
              <img src="../assets/img/icon2.png" alt="">
            </div>
            <div class="l-title">
              医疗行业
            </div>
          </div>
          <div class="center">
            <div class="box" v-for="(item, key) in listMedicalArray" :key="key">
              <div class="icon">
                <img v-lazy="item.bgi" alt="" style="width:210px;height:160px;">
              </div>
              <div class="c-title">{{item.title}}</div>
            </div>
          </div>
          <div class="right">
            <a href="javascript:void(0);" class="more btn">
              <span style="font-size: 16px;">MORE</span>
              <span style="padding-left: 30px; font-size: 20px;">+</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props:{
  //   route:{
  //     type: String
  //   }
  // },
  data () {
    return {
      list3cArray: [
        {
          bgi: require('../assets/img/3c1.png'),
          bgs: '60px',
          title: '自动化车间'
        },
        {
          bgi: require('../assets/img/3c2.png'),
          bgs: '60px',
          title: '零件装配'
        },
        {
          bgi: require('../assets/img/3c3.png'),
          bgs: '60px',
          title: '精密检查'
        },
        {
          bgi: require('../assets/img/3c4.png'),
          bgs: '60px',
          title: '精细加工'
        }
      ],
      listCarArray: [
        {
          bgi: require('../assets/img/car1.png'),
          bgs: '60px',
          title: '高效产线'
        },
        {
          bgi: require('../assets/img/car2.png'),
          bgs: '60px',
          title: '机器人焊接'
        },
        {
          bgi: require('../assets/img/car3.png'),
          bgs: '60px',
          title: '零件加工'
        },
        {
          bgi: require('../assets/img/car4.png'),
          bgs: '60px',
          title: '一体化设计'
        }
      ],
      listBatteryArray: [
        {
          bgi: require('../assets/img/battery1.png'),
          bgs: '60px',
          title: '无人车间'
        },
        {
          bgi: require('../assets/img/battery2.png'),
          bgs: '60px',
          title: '精密器械'
        },
        {
          bgi: require('../assets/img/battery3.png'),
          bgs: '60px',
          title: '电池加工'
        },
        {
          bgi: require('../assets/img/battery4.png'),
          bgs: '60px',
          title: '电池装配'
        }
      ],
      listMedicalArray: [
        {
          bgi: require('../assets/img/medical1.png'),
          bgs: '60px',
          title: '图像检测'
        },
        {
          bgi: require('../assets/img/medical2.png'),
          bgs: '60px',
          title: '高效装配'
        },
        {
          bgi: require('../assets/img/medical3.png'),
          bgs: '60px',
          title: '多元式焊接'
        },
        {
          bgi: require('../assets/img/medical4.png'),
          bgs: '60px',
          title: '智能仓库'
        }
      ]
    }
  },
  // watch:{
  //   route(val,oldval){
  //     if(val !== oldval){
  //       let target = document.getElementById(val)
  //       target.scrollIntoView({behavior:'smooth', block: 'start'})
  //     }
  //   }
  // },
}
</script>

<style lang='less'>
.case{
  .lsh-case{
    position: relative;
    z-index: 50;
    width: 1300px;
    margin: 0 auto;
    padding: 10px 0 0;
    .lsh-title{
        font-size: 48px;
        text-align: center;
        color: #333;
        line-height: 48px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .business-list{
      .list-item{
        height: 180px;
        margin-bottom: 20px;
        background-color: #f0f0f0;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        a{
          color: #000;
          text-decoration: none;
        }
        .left{
          flex: 1 1 220px;
          height: 100%;
          display: flex;
          display: -webkit-flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          transition: all 0.2s linear;
          background-color: #f8b62c;
          .icon{
            height: 50px;
            margin-bottom: 15px;
            transition: all 0.2s linear;
          }
          .l-title{
            font-size: 24px;
            color: #FFFFFF;
          }
        }
        .center{
          flex: 1 1 810px;
          height: 100%;
          display: flex;
          .box{
            flex: 1;
            height: 100%;
            position: relative;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .box:after{
            content: '';
            right: 0;
            height: 90px;
            border-left: 1px solid #dddddd;
            position: absolute;
            top: 35px;
          }
          .icon{
            /* background-image: url('../assets/img/icon_01.png'); */
            background-repeat: no-repeat;
            background-position: center;
          }
          .c-title{
            margin-top: -14px;
            font-size: 18px;
            color: #666666;
            text-align: center;
          }
        }
        .right{
          flex: 1 1 170px;
          .more{
            width: 110px;
            margin: 0 auto;
            height: 40px;
            padding: 0;
            border-radius: 20px;
            background-color: #bbbbbb;
            color: #FFFFFF;
            font-size: 12px;
            font-family: Arial;
            line-height: 40px;
            display: block;
          }
          .btn{
            display: inline-block;
            zoom: 1;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -o-user-select: none;
            user-select: none;
            white-space: nowrap;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
